
import React from 'react';
import './Intro.css';

import iimg from '../images/person1.png'
import imh from '../images/vijay.jpeg'

const Govern = () => {
  return ( 
    <>
 <div className='heading'>
<h1 style={{fontFamily:'serif'}}> OUR GOVERNING BODY</h1>
</div>
    <div className='a-all'>


    <div className='a-image'>
            <div className='g-container'></div>
            <img src={imh} />

        </div>
     
        <div className='a-text'>
            <h2 className='heading2'>Mr Vijay Hashia, Corporate Consultant, Educator, writer</h2>
             <p>
             is the Managing Director  and Founder of SVS Training Resources.  Mr Vijay Hashia has a wealth of knowledge and experience.  He holds a master's degree in Management Studies and is actively engaged in consultancy with corporate organisations and various educational institutions. His consultancy work has enriched the educational experiences at various prestigious institutions, where he has mentored and guided budding engineering and management students through comprehensive training programs.
Shri Vijay Hashia was associated with and conducted a comprehensive Spoken English, Personality Development/Soft Skill and Leadership development programmes at VETA Institutions, Smart Training Institution and Bharti Resources Learning Centres affiliated to Annamalai University.He has educated and developed budding students from prestigious educational institutions, making them employable in the volatile job market. His teaching pattern is exemplary, focusing on practical aspect of personality and soft skill developments and real-world applications.

                
                

             </p>
           
           
             
               
            
        </div>
       
    </div>
    <div  className='ww'>
    <p className='width'>
        An ardent writer, Mr. Vijay Hashia has made significant contributions to the literary world, especially in the realm of philosophical and spiritual discourse.  His insightful articles, published in the Times of India's "Speaking Tree," explore a wide array of topics from self-improvement to spirituality. Notable among his scholarly works are "Material & Spiritual Dimensions of Spiritualism," "You Have the Power to Effect Change," "Good Values as The Bottom Line in Life," "Faith and Reason,” “Right Thought Is the Fulcrum of Happiness," "Optimism Leads to Happiness and Success," "An Ubiquitous Energy Paradigm in Creation,” and many more.  His profound essay, "A Life Living Meaningfully Benefits All," exemplifies his philosophical depth and commitment to meaningful living.  He was invited as a special speaker by SRI KANCHI KAMAKOTI PEETHAM (MATH) broadcast channel to speak on spirituality “ Dynamics of Spanda”  and “ Play of Tattva in Kashmir Shaivsim.” 
In addition to his prolonged contributions to "Speaking Tree," Times of India,       Mr. Vijay Hashia regularly writes for the “Daily Excelsior,” newspaper and     “Spade a Spade," magazine,  where his candid and insightful commentary covers a broad spectrum of subjects including spirituality, religion, politics, society, and culture. Over the past 30 years, he has authored more than  500 articles, enriching readers with his perspectives in publications.
<br/>
<br/>
Shri Vijay Hashia has also been working on a book “Simple Practices of Management,” likely to be published in December 2024. 
Mr. Vijay Hashia derives immense inspiration from his interactions with students and by imparting personality development and soft skill training programmes for their future development. Their curiosity, enthusiasm, and innovative ideas continuously motivate him to expand his knowledge and refine them for the leadership roles.  He believes that students are not just recipients of knowledge but active participants in the learning process. Their unique perspectives and questions challenge him to stay current and creative in his approach, ensuring that his training remains relevant and impactful.  
His life mantra is a testament to his philosophy: "Life's journey is not dependent on the constant acquisition and amassing of possessions, but on the contentment of blissful ways of living." His extensive body of work continues to inspire and enlighten students making a profound impact on their intellectual development. 


        </p>
        </div>



    <div className='a-all'>




<div className='a-text'>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <h2 className='heading2'>Mrs Sunita P
Chief Operating Officer (COO)
</h2>
     <p>
     Mrs Sunita P COO is a dynamic leader with a proven track record in strategic planning and operational excellence. With extensive experience of 30 years in the education and training industry, she is at streamlining processes, enhancing productivity, and fostering a culture of continuous improvement. Mrs Sunita P is expertise in project management, team building, and resource optimization ensures that our programs run smoothly and effectively, providing exceptional value to both academic and professional students.

        
        

     </p>
   
   
     
       <br/>
       <br/>
    
</div>

<div className='a-image'>
            <div className='g-container'></div>
            <img src={iimg} />

        </div>

</div>



<div className='a-all'>


<div className='a-image'>
            <div className='g-container'></div>
            <img src={iimg} />

        </div>
     
<div className='a-text'>
    <br/>
    <br/>
    <h2 className='heading2'>Mrs Anita K
Marketing Head and Public Relations Officer (PRO) 
</h2>
     <p >
     Mrs Anita K, Marketing Head and Public Relations Officer, is a versatile and dynamic professional with a unique blend of expertise in public relations and marketing. With a keen eye for strategic communication and brand management, she is excellent at creating impactful narratives that resonate with both academic and professional audiences.  Her proficiency in media relations, event management, and digital marketing ensures our company maintains a strong and positive presence in the industry.
Combining creative flair with analytical insight, Mrs Anita’s design and implement marketing campaigns that effectively promote our training programs and engage our target audience. Her ability to build and nurture relationships with stakeholders, coupled with their strategic vision, drives our company's growth and enhances our reputation as a leading provider of soft skill resources.

        

     </p>
   
   
     
       
    
</div>


</div>

    </>
  )
}

export default Govern