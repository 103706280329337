import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <div className='ah'>
      <Slider {...settings}>
        <div className='intro'>
          <div className='content'>
            <h1>Welcome to<br/><span className='hi'> SVS Training Resources
</span></h1>
            
          </div>
          <div className='image1' ></div>
        </div>
        <div className='intro'>
          <div className='content'>
          <h1>Welcome to<br/><span className='hi'> SVS Training Resources
</span></h1>

            
          </div>
          <div className='image2' ></div>
        </div>
       
      </Slider>
    </div>
  );
};

export default SliderComponent;
