// src/ComingSoonPage.jsx

import React from 'react';
import './Events.css'; // Import CSS file for styling
import NavTop from '../components/Topfooter';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

const ComingSoonPage = () => {
  return (
    <>
    <NavTop/>
    <NavBar/>
    <div className="coming-soon-container">
      <h1>Coming Soon</h1>
      <p>We're working on something awesome! Stay tuned...</p>
    </div>
    <Footer/>
    </>
  );
}

export default ComingSoonPage;
