import React from 'react';
import './client.css'; // Import the external CSS file

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us">
      <h2>Why Choose Us?</h2>
      <ul>
        <li>
          <i className="fas fa-book-open icon"></i>
          <div>
            <strong>Comprehensive Curriculum:</strong>
            <p>Our courses cover all aspects of soft skills, ensuring a well-rounded development experience.</p>
          </div>
        </li>
        <li>
          <i className="fas fa-chalkboard-teacher icon"></i>
          <div>
            <strong>Experienced Instructors:</strong>
            <p>Learn from professionals with years of experience in their respective fields.</p>
          </div>
        </li>
        <li>
          <i className="fas fa-users icon"></i>
          <div>
            <strong>Community Support:</strong>
            <p>Join a community of like-minded individuals who are also on their journey to personal and professional growth.</p>
          </div>
        </li>
        <li>
          <i className="fas fa-laptop icon"></i>
          <div>
            <strong>Flexible Learning:</strong>
            <p>Access our resources anytime, anywhere, and learn at your own pace.</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default WhyChooseUs;
