import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/web-dev.svg';
import SliderComponent from './Slider';
import NavTop from './Topfooter';

const Hero = () => {
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                  
                    
                    <NavBar />
                </div>
              
                
                  <SliderComponent/>
                </div>
        </>
    )
}

export default Hero;