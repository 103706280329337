import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>

<HashLink className="px-4 font-extrabold text-blue-900 hover:text-blue-900" smooth to="/">
                Home
            </HashLink>
        <HashLink className="px-4 font-extrabold text-blue-900 hover:text-blue-900" smooth to="/abouts">
                About us
            </HashLink>
           
            <HashLink className="px-4 font-extrabold text-blue-900 hover:text-blue-900" smooth to="/#services">
               Our Courses
            </HashLink>
            <HashLink className="px-4 font-extrabold text-blue-900 hover:text-blue-900" smooth to="/events">
               Gallery
            </HashLink>
           
            <HashLink className="px-4 font-extrabold text-blue-900 hover:text-blue-900" smooth to="/events">
               Events
            </HashLink>
           
            <HashLink className="px-4 font-extrabold text-blue-900 hover:text-blue-900" to="/contact#contact">
                Contact 
            </HashLink>

            <a
  href="tel:966386589"
  style={{ backgroundColor: 'blue', padding: '10px' }}
  className="px-4 font-extrabold text-white hover:text-blue-900 rounded"
>
  Call Us
</a>

            
        </>
    )
}

export default NavLinks;
