import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';

import About from '../components/Intro';

import Govern from '../components/Govern';
import Admin from '../components/Administration';
import Staff from '../components/Staff';


const Home = () => {
    return (
        <>
            <Hero />
            <About />
            <br/>
            <Govern/>
            <br/>
             <Admin/>
             <br/>
             <Staff/>
            <Services />
            <Portfolio />
             
            <Clients />
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

