
import React from 'react';
import './About.css';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';
import img from '../images/home.jpeg';
import img1 from '../images/gather.jpeg'
import Govern from '../components/Govern';
import Admin from '../components/Administration';
import Staff from '../components/Staff';


const Abouts = () => {
  return ( 
    <>
    <div>
                    <NavBar />
                </div>
                <br/>
    <div className='a-all'>
        <div className='a-image'>
            <div className='g-container'></div>
            <img src={img} />

        </div>

        <div className='a-text'>
             <h1>About Us</h1>
             <p>
             SVS Training Resources is the pioneer in training and the largest employability enhancer in India.  Established by Shri Vijay Hashia, the Managing Director in the year 2013, the company had been on a low activity.    Now, SVS Training Resources have defined set of learning outcomes achieved through a combination of theoretical inputs, programming tasks and reinforcement exercises.  SVS Training Resources is designed to make the students exhibit their speaking skills in English with confidence and ease.
                <br/>
                
                SVS Training Resources trains students on relevant employability skills like Language and Communication Skills, Motivation & Confidence, Interview Skills, Group Discussions Skills, Public Speaking Skills, Life Skills, and Aptitude Skills to enhance their overall “Employability Quotient” and transform them into “ready to deploy” talent mapped to industry requirements.
                <br/>
SVS Training Resources provides high-quality, customized training programs that empower students and organizations to achieve their goals and drive success.<br/>
SVS Training Resources sets the foundation for students to move in the right direction and make them focussed to achieve their academic and career objectives.<br/>

                

             </p>
           
             
               
            
        </div>
    </div>


    <div className='a-all'>
       

        <div className='a-text'>
           
             <p>
             SVS Training Resources has structured programme to navigate both on-campus and off-campus recruitment processes, providing comprehensive exposure to recruitment materials.<br/>
SVS Training Resource has a rich history of nurturing young minds and fostering academic brilliance, and committed to providing a dynamic and supportive learning environment.<br/>
Through a diverse range of programs and extracurricular activities, SVS aims to cultivate well-rounded individuals equipped with the skills and knowledge to thrive in an ever-changing world.<br/>
With a shared passion for knowledge dissemination and professional development, our faculty is dedicated to empowering employees with the tools, insights, and resources needed to excel in their roles and contribute meaningfully to SVS's mission.<br/>
SVS believe in the transformative power of education and are dedicated to shaping the leaders of tomorrow.<br/>

                

             </p>
           
            
             
               <br/>
               <br/>
            
        </div>



        <div className='a-image'>
            <div className='g-container'></div>
            <img src={img1} />

        </div>
    </div>

    <br/>
    <Govern/>
    <Admin/>
    <Staff/>

    <Footer/>
    </>
  )
}

export default Abouts