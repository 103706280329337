
import React from 'react';
import './Intro.css';
import iiimg from '../images/person3.webp'



const Admin = () => {
  return ( 
    <>
 <div className='heading'>
<h1 style={{fontFamily:'serif'}}> OUR ADMINISTRATION</h1>
</div>
    <div className='a-all'>


    
     
        <div className='a-text'>
            <h2 className='heading2'>Mr Virender K , 
Administrator & Corporate Relations
</h2>
             <p>
             Mr Virender K is a retired bank officer. An effective administrator with an exceptional organisational skill, strong communication abilities.  He is also a strategic thinker and identifies and implements plans to improve administrative processes and fosters corporate partnerships.
Mr Virender K has exceptional problem-solving skills and addresses and resolves administrative and relational challenge with innovative solutions.  With his experience and expertise, he brings a wealth of experience in administration and corporate relations, leveraging past experiences to enhance current practices. 

                
                

             </p>
           
           
             
               
            
        </div>


        <div className='a-image'>
            <div className='g-container'></div>
            <img src={iiimg} />

        </div>
    </div>









    </>
  )
}

export default Admin