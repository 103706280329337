import React from 'react';
import './Staff.css';

const staff = [
  { name: 'Mrs Shantha', title: 'Freelance Trainer' },
  { name: 'Mr Tejpal', title: 'Trainer' },
  { name: 'Ms Poornima', title: 'Trainer' },
  { name: 'Mr Lokesh', title: 'Head Accounts Department' },
  { name: 'Mr Nagesh Gowda', title: 'Head HR' },
];

const Staff = () => {
  return (
    <div className="staff-container">
<div className='heading'>
<h1 style={{fontFamily:'serif'}}> OUR STAFF</h1>
</div>
      <table className="staff-table">
        <thead>
          <tr>
            <th>Staff Members</th>
            <th>Department</th>
          </tr>
        </thead>
        <tbody>
          {staff.map((member, index) => (
            <tr key={index}>
              <td>{member.name}</td>
              <td>{member.title}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Staff;
