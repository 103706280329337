
import React from 'react';
import './Intro.css';
import imgg from '../images/home.jpeg';



const About = () => {
  return ( 
    <>
    <div className='a-all'>
        <div className='a-image'>
            <div className='g-container'></div>
            <img src={imgg} />

        </div>

        <div className='a-text'>
             <h1>About Us</h1>
             <p>
             SVS Training Resources is the pioneer in training and the largest employability enhancer in India.  Established by Shri Vijay Hashia, the Managing Director in the year 2013, the company had been on a low activity.    Now, SVS Training Resources have defined set of learning outcomes achieved through a combination of theoretical inputs, programming tasks and reinforcement exercises.  SVS Training Resources is designed to make the students exhibit their speaking skills in English with confidence and ease.
                <br/>
                <br/>
                SVS Training Resources sets the foundation for students to move in the right direction and make them focussed to achieve their academic and career objectives.
                 <br/>
                 <br/>
SVS Training Resource has a rich history of nurturing young minds and fostering academic brilliance, and committed to providing a dynamic and supportive learning environment.
                
               <br/>
             </p>
           
             <a href='/abouts'><button className='h-button'>Read More</button></a>
             
               <br/>
               <br/>
            
        </div>
    </div>
    </>
  )
}

export default About